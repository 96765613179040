import * as React from 'react';
import { CaretDown, CaretUp } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { Collapse } from 'react-collapse';
import isEqual from 'react-fast-compare';

export type PanelProps = {
    children: React.ReactNode;
    className?: string;
    collapsible?: boolean;
    expanded?: boolean;
    header?: React.ReactNode;
    headerClassName?: string;
};

const Panel = ({ children, className, collapsible, expanded, header, headerClassName = '' }: PanelProps) => {
    const [collapsed, setCollapsed] = React.useState<boolean>(expanded || true);

    const collapsibleHeader = (
        <div onClick={() => setCollapsed(!collapsed)}>
            {Boolean(header) && header}

            {collapsed ? (
                <CaretDown
                    data-testid="down-arrow"
                    size="xs"
                    style="fill"
                />
            ) : (
                <CaretUp
                    data-testid="up-arrow"
                    size="xs"
                    style="fill"
                />
            )}
        </div>
    );

    const normalHeader = Boolean(header) && <div className={headerClassName}>{header}</div>;

    const panelHeader = collapsible ? collapsibleHeader : normalHeader;

    return (
        <div className={className}>
            {panelHeader}
            {collapsible ? (
                <Collapse isOpened={!collapsed}>
                    <div>{children}</div>
                </Collapse>
            ) : (
                <div>{children}</div>
            )}
        </div>
    );
};

export default React.memo<PanelProps>(Panel, isEqual);
